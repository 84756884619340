<template>
  <div>
    <div
      class="row"
      id="whiteDiv"
      style="
        background-color: white;
        margin-bottom: 2.5%;
        padding: 10px 5px 4px 5px;
      "
    >
      <div class="col-md-12">
        <div class="row">
          <div class="banners3 banners">
            <div class="item1 col-md-3 col-sm-12">
              <a id="dBanner1" href="#"></a>
            </div>
            <div class="item2 col-md-6 col-sm-12">
              <a id="dBanner2" href="#"></a>
            </div>
            <div class="item3 col-md-3 col-md-12">
              <a id="dBanner3" href="#"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixinAdvertisments from "@/mixins/APICommon";

export default{
  name: "HomePagediscount",
  mixins: [mixinAdvertisments],
  created (){
    this.getBanners();
  },
  methods:{
    getBanners: async function () {
      try {
        let response = await this.getAdvertisments();
        let bannerdata1 = response.filter(function (res) {
          return res.image_type === 3;
        });
        document.getElementById("dBanner1").style.backgroundImage = "url(" + bannerdata1[0].image + ")";

        let bannerdata2 = response.filter(function (res) {
          return res.image_type === 4;
        });
        document.getElementById("dBanner2").style.backgroundImage = "url(" + bannerdata2[0].image + ")";

        let bannerdata3 = response.filter(function (res) {
          return res.image_type === 5;
        });
        document.getElementById("dBanner3").style.backgroundImage = "url(" + bannerdata3[0].image + ")";
        
      } catch (error) {
        console.log(error);
      }
    }
  }

};
</script>

<style scoped>
.banners3 {
  width: 100%;
  margin-bottom: 0px !important;
}

.row {
  text-align: center;
}

#whiteDiv {
  background-color: white;
  margin-bottom: 2.5%;
  padding: 10px 5px 4px 5px;
}

.item1 a {
  background-image: url("https://demo.smartaddons.com/templates/html/supermarket/image/catalog/banners/banner3.jpg");
  background-position: center;
  background-size: cover;
  height: 350px;
}

.item2 a {
  background-image: url("https://demo.smartaddons.com/templates/html/supermarket/image/catalog/banners/banner4.jpg");
  background-position: center;
  background-size: cover;
  height: 350px;
}

.item3 a {
  background-image: url("https://demo.smartaddons.com/templates/html/supermarket/image/catalog/banners/banner5.jpg");
  background-position: center;
  background-size: cover;
  height: 350px;
}

@media screen and (max-width: 992px) {
  #whiteDiv {
    padding: 5px 5px 4px 5px;
  }
  .banners .item1 {
    display: block !important;
    width: inherit !important;
    padding: 5px 5px !important;
  }

  .banners .item2 {
    display: block !important;
    width: inherit !important;
    padding: 5px 5px !important;
  }

  .banners .item3 {
    display: block !important;
    width: inherit !important;
  }
}
</style>