<template>
  <div>

    <div>
      <customer-services></customer-services>
      <discount-banners></discount-banners>

      <div
            v-if="blockLoading"
            id="infiniteScrollLoader"
            class="text-center"
            v-infinite-scroll="getProductsHomePage"
            :infinite-scroll-disabled="!blockLoading"
            infinite-scroll-distance="10"
          >
      </div>

      <!-- flash D start -->
      <div
        v-if="finalblocks.length > 1"
        class="row"
        style="background-color: white; margin-bottom: 2.5%"
      >
        <div class="col-md-12" style="padding: 2.5%; padding-bottom: 0px">
          <div v-for="(item, index) in finalblocks" :key="index">
            <component
              :is="item.blockcompnent"
              v-bind:noslide="true"
              v-bind:data="item"
            ></component>
          </div>
        </div>
      </div>
      <!-- flash D end -->
      <!-- <product-group-slider></product-group-slider> -->
      
      <div id="content" class="col-md-12">
        <div class="products-category" style="min-height: 500px">
          <!--changed listings-->
          <div class="products-list row nopadding-xs so-filter-gird">
            <div v-if="noproducts" class="row">
              <div class="col-md-3"></div>
              <div
                class="col-md-6"
                style="
                  text-align: center;
                  background-color: #e7e7e7;
                  padding: 20px;
                  border-radius: 5px;
                "
              >
                <h3>There are no products available in your area</h3>
                <p>Please change location in your profile and browse items</p>
                <button
                  class="btn btn-lg"
                  style="
                    background-color: rgb(255, 211, 56);
                    color: rgb(0, 0, 0);
                    border: none;
                  "
                  @click="handleProfile()"
                >
                  Go To Profile
                </button>
              </div>
              <div class="col-md-3"></div>
            </div>
            <span v-for="(mitem, mindex) in gridblocks" :key="mindex">
              <!-- <div style="font-size: 30px">
                {{ mitem.title }}
                <hr
                  style="border-top: 5px dashed red; width: 100px; margin-top: 5px; margin-left: 0px; border-radius: 5%"
                />
              </div>-->
              <div class="row" style="background-color: white">
               
                <div
                  class="col-md-12"
                  style="padding: 2.5%; padding-bottom: 0px"
                >

                <!-- <div style="text-align: end; font-size: small; padding: 0px">
                  <a @click="haddleFlashDeal()">View All</a>
                </div> -->

                  <h1
                    class="title-category"
                    style="
                      font-size: 2.6rem;
                      font-weight: 700;
                      margin-bottom: 2.5%;
                    "
                  >
                    {{ mitem.title }}
                    <!-- <div style="text-align: end; font-size: small; padding: 0px;  font-weight: 400;"> -->
                      <!-- <a>View All</a> -->
                      <!-- <a @click="haddleClickViewAll(mitem._id)">View All</a> -->
                    <!-- </div> -->
                  </h1>

                  <div v-for="(item, index) in mitem.data" :key="index">
                  {{ item.length }}
                    <component
                      :is="'ProductTile'"
                      v-bind:noslide="true"
                      v-bind:data="item"
                    ></component>
                  </div>
                </div>
              </div>
              <!-- <div
                class="col-md-12"
                style="text-align: center;  padding-left: 0px;"
              >
                <button
                  style="border-radius: 4px;"
                  type="button"
                  class="btn btn-default"
                  @click="mitem.pagi = mitem.pagi + 5"
                >
                  View More
                </button>
              </div>-->
            </span>
          </div>
          <!--// End Changed listings-->
          <!-- Filters -->
          <!-- <div class="product-filter product-filter-bottom filters-panel">
                        <div class="row">
                            <div class="col-sm-6 text-left"></div>
                            <div class="col-sm-6 text-right">Showing 1 to 15 of 15 (1 Pages)</div>
                        </div>
          </div>-->
          <!-- //end Filters -->
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="productModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="productModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header" style="display: flex; justify-content: space-between; align-items: end;">
            <h5 class="modal-title" id="productModalLongTitle">
              Product Quick View
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span style="font-size: 30px; font-weight: initial; color: black; color: black !important;" aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ProductModalContent
              ref="ProductModalRef"
              v-bind:pid="this.product_id"
              v-bind:count="this.uniqcount"
            />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn"
              data-dismiss="modal"
              style="background-color: white !important; border: 1px solid black !important; border-radius: 5px; color: black;"
            >
              Close
            </button>
            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
          </div>
        </div>
      </div>
    </div>

    <!-- City Modal -->
    <div
      style="margin-top: 3%"
      class="modal fade"
      id="cityModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="cityModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4
              style="text-align: center"
              class="modal-title"
              id="cityModalLongTitle"
            >
              Select your location here
            </h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              style="margin-top: -20px"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="text-align: center">
            <p>To get your exact location please select your locations.</p>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-8">
                <div class="form-group">
                  <select
                    v-model="selectedRegion"
                    id="input-sort"
                    class="form-control"
                  >
                    <option value selected="selected">- Select Region -</option>
                    <option
                      v-for="(item, index) in regionArray"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.region_name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-2"></div>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-8">
                <div class="form-group">
                  <select
                    v-model="selectedCity"
                    id="input-sort"
                    class="form-control"
                  >
                    <option value selected="selected">- Select City -</option>
                    <option
                      v-for="(item, index) in cityArray"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.city_name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-2"></div>
            </div>
          </div>
          <div class="modal-footer" style="text-align: center">
            <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
            <button
              style="
                background-color: rgb(255, 211, 56);
                color: rgb(0, 0, 0);
                border: none;
              "
              type="button"
              class="btn btn-lg btn-primary"
              @click="filterProductsByCity()"
              :disabled="selectedCity == '' || selectedRegion == ''"
            >
              Search Store
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Agent Modal -->
    <div
      style="margin-top: 3%"
      class="modal fade"
      id="agentCityModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="cityModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4
              style="text-align: center"
              class="modal-title"
              id="cityModalLongTitle"
            >
              Order Information
            </h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              style="margin-top: -20px"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="padding: 0">
            <div class="col-md-12" style="padding: 0">
              <table
                class="table table-responsive"
                id="orderInfoTable"
                style="color: black; border: 1px solid #ddd"
              >
                <tr style="line-height: 40px; padding: 10px 15px">
                  <td style="font-size: 14px; padding: 0px 15px">Mobile No</td>
                  <td style="font-size: 14px; padding: 0px 15px">Home No</td>
                </tr>
                <tr style="padding: 10px 15px">
                  <td style="font-size: 14px; padding: 0px 15px">
                    <VuePhoneNumberInput
                      v-model="guest_form.mobile"
                      @input="validatePhone(guest_form.mobile)"
                      default-country-code="LK"
                      :only-countries="['LK']"
                      no-example
                      style="height: 30px"
                    />
                  </td>
                  <td style="font-size: 14px; padding: 0px 15px">
                    <VuePhoneNumberInput
                      ref="mobilecom1"
                      valid-color="green"
                      @input="validatePhone1(guest_form.user_home_mobile)"
                      v-model="guest_form.user_home_mobile"
                      default-country-code="LK"
                      :translations="{ phoneNumberLabel: '' }"
                      :only-countries="['LK']"
                      no-example
                      style="height: 30px; cursor: auto"
                      id="mobileInput1"
                    />
                  </td>
                </tr>
                <tr style="line-height: 40px; padding: 10px 15px">
                  <td style="font-size: 14px; padding: 10px 15px 0px">
                    First Name
                  </td>
                  <td style="font-size: 14px; padding: 10px 15px 0px">
                    Last Name
                  </td>
                </tr>
                <tr style="line-height: 40px; padding: 10px 15px">
                  <td style="font-size: 14px; padding: 0px 15px">
                    <input
                      type="text"
                      name="First Name"
                      v-model="guest_form.first_name"
                      placeholder="First Name"
                      class="form-control"
                    />
                  </td>
                  <td style="font-size: 14px; padding: 0px 15px">
                    <input
                      type="text"
                      name="First Name"
                      v-model="guest_form.last_name"
                      placeholder="Last Name"
                      class="form-control"
                    />
                  </td>
                </tr>
                <tr style="line-height: 40px; padding: 10px 15px">
                  <td style="font-size: 14px; padding: 0px 15px">Province</td>
                </tr>
                <tr style="line-height: 40px; padding: 10px 15px">
                  <td colspan="2" style="font-size: 14px; padding: 0px 15px">
                    <select
                      class="form-control"
                      @change="changeProvince($event.target.value, 0, true)"
                      v-model="selectedprovince"
                    >
                      <option value>--- Please Select ---</option>
                      <option
                        v-for="(item, val) in provinceArray[0]"
                        :key="val"
                        :value="item._id"
                      >
                        {{ item.province_name }}
                      </option>
                    </select>
                  </td>
                </tr>
                <tr style="line-height: 40px; padding: 10px 15px">
                  <td style="font-size: 14px; padding: 0px 15px">
                    District / Area
                  </td>
                </tr>
                <tr style="line-height: 40px; padding: 10px 15px">
                  <td colspan="2" style="font-size: 14px; padding: 0px 15px">
                    <select
                      class="form-control"
                      @change="changeRegion($event.target.value, 0, true)"
                      v-model="selectedregion"
                    >
                      <option value>--- Please Select ---</option>
                      <option
                        v-for="(item, val) in regionArray[0]"
                        :key="val"
                        :value="item._id"
                      >
                        {{ item.region_name }}
                      </option>
                    </select>
                  </td>
                </tr>
                <tr style="line-height: 40px; padding: 10px 15px">
                  <td style="font-size: 14px; padding: 0px 15px">City</td>
                </tr>
                <tr style="line-height: 40px; padding: 10px 15px">
                  <td colspan="2" style="font-size: 14px; padding: 0px 15px">
                    <select
                      class="form-control"
                      @change="changeAgentCity()"
                      v-model="selectedcityAgent"
                    >
                      <option value>--- Please Select ---</option>
                      <option
                        v-for="(item, val) in cityArray[0]"
                        :key="val"
                        :value="item._id"
                      >
                        {{ item.city_name }}
                      </option>
                    </select>
                  </td>
                </tr>
                <tr style="line-height: 40px; padding: 10px 15px">
                  <td style="font-size: 14px; padding: 0px 15px">
                    Address Line 1
                  </td>
                </tr>
                <tr style="line-height: 120px; padding: 10px 15px">
                  <td colspan="2" style="font-size: 14px; padding: 0px 15px">
                    <textarea
                      v-model="guest_form.delivery_address"
                      rows="3"
                      cols="12"
                      type="text"
                      name="Delivery Address"
                      placeholder="Address Line 1"
                      class="form-control"
                      style="height: auto"
                    ></textarea>
                  </td>
                </tr>
                <tr style="line-height: 40px; padding: 10px 15px">
                  <td style="font-size: 14px; padding: 0px 15px">
                    Address Line 2
                  </td>
                </tr>
                <tr style="line-height: 120px; padding: 10px 15px">
                  <td
                    colspan="2"
                    style="font-size: 14px; padding: 0px 10px 15px"
                  >
                    <textarea
                      v-model="guest_form.billing_address"
                      rows="3"
                      cols="12"
                      type="text"
                      name="Billing Address"
                      placeholder="Address Line 2"
                      class="form-control"
                      style="height: auto"
                    ></textarea>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="modal-footer" style="text-align: center">
            <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
            <button
              style="
                background-color: rgb(255, 211, 56);
                color: rgb(0, 0, 0);
                border: none;
              "
              type="button"
              class="btn btn-primary"
              @click="filterProductsByCity()"
              :disabled="selectedcityAgent == ''"
            >
              Search Store
            </button>

            <button
              style="
                background-color: rgb(255, 211, 56);
                color: rgb(0, 0, 0);
                border: none;
              "
              type="button"
              class="btn btn-danger"
              @click="resetAgentCity()"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- End of the Mobile phones -->
  </div>
</template>

<script>
// SUB BLOCK COMPONENTS
import ProductTile from "@/components/product/product_tile_new.vue";
import ProductTileFull from "@/components/product/product_tile_full.vue";

// BANNER COMPONENTS
import Banner3 from "@/components/banners/Banner3.vue";
import Banner4 from "@/components/banners/Banner4.vue";
import StaticCates from "@/components/banners/StaticCates.vue";

// BLOCK COMPONENTS
import ProductSliderBlock from "@/components/product/ProductSliderBlock.vue";
import ProductSliderGridBlock from "@/components/product/ProductSliderGridBlock.vue";

import ProductTileBrand from "@/components/product/product_tile_brand.vue";
import ProductTileGrid from "@/components/product/product_tile_grid.vue";

import ProductModalContent from "@/components/product/product_modal.vue";

import mixinProduct from "@/mixins/buyer/APIProduct";
import mixinPromotions from "@/mixins/buyer/APIPromotions";
import mixinAdvertisments from "@/mixins/APICommon";

import VuePhoneNumberInput from "vue-phone-number-input";

import CustomerServices from "@/components/product/customer_services.vue";
import DiscountBanners from "@/components/product/discount_banners.vue";
// import ProductGroupSlider from "@/components/product/product_group_slider.vue";

export default {
  name: "home",
  components: {
    ProductSliderBlock,
    ProductSliderGridBlock,
    ProductTile,
    ProductTileFull,
    Banner3,
    Banner4,
    StaticCates,
    ProductModalContent,
    ProductTileGrid,
    ProductTileBrand,
    VuePhoneNumberInput,
    CustomerServices,
    DiscountBanners
    // ProductGroupSlider,
  },
  mixins: [mixinProduct, mixinPromotions, mixinAdvertisments],
  data: function () {
    return {
      trendingCategories: [],
      blocks: [],
      gridblocks: [],
      homeGridNewArrivals: [],
      homeGridMostPopular: [],
      homeGridOurPromotions: [],
      finalblocks: [],
      product_id: "",
      inputNumValue: 1,
      isDisable: true,
      idObj: {
        ids: [],
      },
      selectedproductImage: "",
      wishlit: false,
      blockLoading: true,
      hmobileValid: true,
      dealHours: 0,
      dealMinutes: "0.0",
      dealSeconds: 0,
      regionArray: [],
      cityArray: [],
      selectedRegion: "",
      selectedCity: "",
      noproducts: false,
      user_role: 0,
      agent_email: "",
      guest_form: {
        first_name: "",
        last_name: "",
        mobile: "",
        delivery_address: "",
        billing_address: "",
      },
      selectedprovince: "",
      selectedregion: "",
      selectedcity: "",
      selectedcityAgent: "",
      selectedAddressLine1: "",
      selectedAddressLine2: "",
      countries: [],
      province: [],
      region: [],
      city: [],
      provinceArray: [],
      regionArray: [],
      cityArray: [],
      uniqcount: 0,
    };
  },
  metaInfo: {
    title: "Cochchi.lk",
    titleTemplate: "%s | National eCommerce Platform | Shop online in Sri Lanka",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Cochchi.lk online shopping site in Sri Lanka is available for your Daily Essential Needs. Home Delivery (Within Delivery Grid).We deliver grocery delivery and daily essentials etc... Online Order.",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  watch: {
    $route(to, from) {
      // this.getPageHome(to.name);
      this.popAgentModal();
      this.getHomePageProductList(to.name);
    },
    selectedRegion: function (newVal, oldVal) {
      if (newVal === "") {
        this.selectedCity = "";
        this.cityArray = [];
      } else {
        this.selectedCity = "";
        this.cityArray = [];
        this.fetchCity();
      }
    },
    "$store.state.buyer_accessToken": function (newVal, oldVal) {
      this.popAgentModal();
      // this.getProductsHomePage();
    },
  },
  created() {
    this.menuLock();

    this.display("grid");
    // this.getProductsHomePage();

    this.initiateList();
    // this.fetchRegion("5d932c7a570adc5007bdb5dc");
    // this.getPageHome(this.$route.name);
    // this.getHomePageProductList(this.$route.name);
    // this.getTrendingCategories();
  },
  beforemounted: function () {
    $(document).ready(function () {
      $(".yt-content-slider").each(function () {
        var $slider = $(this),
          $panels = $slider.children("div"),
          data = $slider.data();
        // Remove unwanted br's
        //$slider.children(':not(.yt-content-slide)').remove();
        // Apply Owl Carousel

        $slider.owlCarousel2({
          responsiveClass: true,
          mouseDrag: true,
          video: true,
          lazyLoad: data.lazyload == "yes" ? true : false,
          autoplay: data.autoplay == "yes" ? true : false,
          autoHeight: data.autoheight == "yes" ? true : false,
          autoplayTimeout: data.delay * 1000,
          smartSpeed: data.speed * 1000,
          autoplayHoverPause: data.hoverpause == "yes" ? true : false,
          center: data.center == "yes" ? true : false,
          loop: data.loop == "yes" ? true : false,
          dots: data.pagination == "yes" ? true : false,
          nav: data.arrows == "yes" ? true : false,
          dotClass: "owl2-dot",
          dotsClass: "owl2-dots",
          margin: data.margin,
          navText: ["", ""],

          responsive: {
            0: {
              items: data.items_column4,
            },
            480: {
              items: data.items_column3,
            },
            768: {
              items: data.items_column2,
            },
            992: {
              items: data.items_column1,
            },
            1200: {
              items: data.items_column0,
            },
            1366: {
              items: data.items_column00,
            },
            1650: {
              items: data.items_column000,
            },
            1920: {
              items: data.items_column0000,
            },
          },
        });
      });
    });

    // if(!this.checkedLogged()){
    //     if(!localStorage.user_city){
    //         $('#cityModalCenter').modal('show');
    //     }
    // }

    this.popAgentModal();

    const mobileInputTp = document.querySelector(".input-tel__input");
    mobileInputTp.setAttribute("maxlength", 9);

    // Remove placeholder in mobile
    this.$nextTick(function () {
      const agentMobileInputs = document.querySelectorAll(".input-tel__input");
      const countryContainers = document.querySelectorAll(
        ".select-country-container"
      );
      const countrySelectInputs = document.querySelectorAll(
        ".country-selector__input"
      );
      const countrySelectorFlags = document.querySelectorAll(
        ".country-selector__country-flag"
      );
      const countrySelectorArrows = document.querySelectorAll(
        ".country-selector__toggle"
      );

      agentMobileInputs.forEach((element) => {
        element.style.boxShadow = "none";
        element.placeholder = "";
        element.style.cursor = "auto";
        element.nextElementSibling.style.display = "none";
        element.style.paddingTop = "0px";
        element.style.lineHeight = "40px";
      });

      countryContainers.forEach((element) => {
        element.style.width = "90px";
        element.style.minWidth = "90px";
        element.style.flex = "0 0 90px";
      });

      countrySelectInputs.forEach((element) => {
        element.style.paddingTop = "0px";
        element.style.lineHeight = "40px";
      });

      countrySelectorFlags.forEach((element) => {
        element.style.top = "14px";
      });

      countrySelectorArrows.forEach((element) => {
        element.style.top = "calc(50% - 13px)";
      });
    });
  },
  methods: {
    handleProfile() {
      if (!localStorage.buyer_data) {
        this.$router.push("/buyer-profile") ;
        return;
      }
      let user = JSON.parse(localStorage.getItem("buyer_data"));
      this.userRole = user.user_role != undefined ? user.user_role : "";
      if (this.userRole == 2) {
        $("#agentCityModalCenter").modal("show");
        return;
      } else {
        // this.$router.push("/buyer-profile").catch(err => {});
        this.$router.push({
          name: "Buyer Profile",
          params: { availableAddress: true },
        });
      }
    },
    resetAgentCity() {
      this.guest_form = {
        first_name: "",
        last_name: "",
        mobile: "",
        delivery_address: "",
        billing_address: "",
      };
      this.selectedprovince = "";
      this.selectedregion = "";
      this.selectedcity = "";
      this.selectedcityAgent = "";
      this.selectedAddressLine1 = "";
      this.selectedAddressLine2 = "";

      localStorage.removeItem("agent_guestdata");
    },
    changeCountries: async function (event, index, useraction = false) {
      try {
        let response = await this.getProvince(event);
        this.province = response;

        this.$set(this.provinceArray, index, response);
        this.regionArray[index] = [];
        this.cityArray[index] = [];

        // this.provinceArray[index] = response;

        // // RESETING
        if (Object.keys(response).length === 0 || useraction === true) {
          this.addressArray[index].province = "";
          this.addressArray[index].region = "";
          this.addressArray[index].city = "";
        }
      } catch (error) {
        console.log(error);
      }
    },
    changeProvince: async function (event, index, useraction = false) {
      try {
        let response = await this.getRegion(event);
        this.region = response;

        this.$set(this.regionArray, index, response);

        // RESETING
        this.cityArray[index] = [];
        if (Object.keys(response).length === 0 || useraction === true) {
          this.addressArray[index].region = "";
          this.addressArray[index].city = "";
          this.selectedregion = "";
          this.selectedcity = "";
        }
      } catch (error) {
        console.log(error);
      }
    },
    changeRegion: async function (event, index, useraction = false) {
      try {
        let response = await this.getCity(event);
        this.city = response;

        this.$set(this.cityArray, index, response);

        // RESETING
        if (Object.keys(response).length === 0 || useraction === true) {
          this.addressArray[index].city = "";
          this.selectedcity = "";
        }
      } catch (error) {
        console.log(error);
      }
    },
    validatePhone(key) {
      if (key === "0") {
        this.guest_form.mobile = "";
      }
      key = key.replace(/^0+/, "");
      key = key.replace(/ /g, "");
      this.guest_form.mobile = key;
      if (key.length == 9) {
        this.changeColor.border = "1px solid red";
        this.mobileValid = true;
      } else if (key.length === 10 || key.length < 10) {
        this.changeColor.border = "1px solid #ccc";
        this.mobileValid = false;
      }
    },
    validatePhone1(key) {
      if (key.length >= 10) {
        this.$refs.mobilecom1.$children[1].$el.firstChild.style.border =
          "1px solid green";
        this.$refs.mobilecom1.$children[1].$el.firstChild.style.boxShadow =
          "green 0px 0px 0px 0px";
        this.$refs.mobilecom1.$children[1].$el.firstChild.style.caretColor =
          "green 0px 0px 0px 0px";
      }
      if (key.length < 10) {
        this.$refs.mobilecom1.$children[1].$el.firstChild.style.border =
          "1px solid red";
        this.$refs.mobilecom1.$children[1].$el.firstChild.style.boxShadow =
          "red 0px 0px 0px 0px";
        this.$refs.mobilecom1.$children[1].$el.firstChild.style.caretColor =
          "red 0px 0px 0px 0px";
      }

      if (!this.guest_form.hasOwnProperty("user_home_mobile")) {
        this.hmobileValid = false;
        // this.hmobileError = "Enter valid phone number"
        return;
      }

      var str = key;
      var res = str.substr(0, 9);
      this.guest_form.user_home_mobile = res;
      key = res;

      if (key === "0") {
        this.guest_form.user_home_mobile = "";
      }
      key = key.replace(/^0+/, "");
      key = key.replace(/ /g, "");
      this.guest_form.user_home_mobile = key;

      if (key.length == 9) {
        if (key == this.guest_form.user_home_mobile.substring(3)) {
          this.hmobileValid = false;
          // this.hmobileError = "Existing Phone Number Detected"
          this.$refs.mobilecom1.$children[1].$el.firstChild.style.borderColor =
            "red";
        } else {
          this.hmobileValid = true;
        }
      } else if (key.length === 10 || key.length < 10) {
        this.hmobileValid = false;
        // this.hmobileError = "Enter valid phone number"
      }
    },
    // validatePhone1(key) {
    //   if (key === "0") {
    //     this.guest_form.user_home_mobile = "";
    //   }
    //   key = key.replace(/^0+/, "");
    //   key = key.replace(/ /g, "");
    //   this.guest_form.user_home_mobile = key;
    //   if (key.length == 9) {
    //     this.changeColor1.border = "1px solid red";
    //     this.mobileValid1 = true;
    //   } else if (key.length === 10 || key.length < 10) {
    //     this.changeColor1.border = "1px solid #ccc";
    //     this.mobileValid1 = false;
    //   }
    // },
    changeAgentCity: async function () {
      // await this.calculateTotalfee();
    },
    changeColor() {
      return "";
    },
    changeColor1() {
      return "";
    },
    popAgentModal: async function () {
      if (localStorage.buyer_data) {
        let userdata = JSON.parse(localStorage.buyer_data);
        if (userdata.hasOwnProperty("user_role")) {
          this.user_role = userdata.user_role;
          this.agent_email = userdata.email;
        }
        await this.changeCountries("5d8b9e5dfb195816aaea61b3", 0);
      }

      if (this.user_role == 2) {
        if (this.user_role == 2) {
          if (localStorage.agent_guestdata) {
            let agentguest_obj = JSON.parse(localStorage.agent_guestdata);
            this.guest_form = agentguest_obj;
            this.selectedregion = agentguest_obj.selectedregion;
            this.selectedprovince = agentguest_obj.selectedprovince;
            this.selectedcityAgent = agentguest_obj.selectedcity;
            await this.changeCountries("5d8b9e5dfb195816aaea61b3", 0);
            await this.changeProvince(this.selectedprovince, 0);
            await this.changeRegion(this.selectedregion, 0);
          }
        }
        if (!localStorage.agent_guestdata) {
          $("#agentCityModalCenter").modal("show");
        }
      }
    },
    filterCityName() {
      if (localStorage.user_city_name) {
        return " - " + localStorage.user_city_name;
      } else {
        return "";
      }
    },
    checkedLogged() {
      if (localStorage.buyer_token) {
        return true;
      } else {
        return false;
      }
    },
    filterProductsByCity() {
      this.guest_form.selectedcity = this.selectedcityAgent;
      this.guest_form.selectedprovince = this.selectedprovince;
      this.guest_form.selectedregion = this.selectedregion;

      localStorage.setItem("agent_guestdata", JSON.stringify(this.guest_form));
      this.cityArray.forEach((element) => {
        if (this.selectedCity == element._id) {
          localStorage.setItem("user_city_name", element.city_name);
        }
      });
      // this.getProductsHomePage();
      $("#agentCityModalCenter").modal("hide");
    },
    fetchRegion: async function (id) {
      let response = await this.getRegion(id);
      this.regionArray = response;
    },
    fetchCity: async function (id) {
      let response = await this.getCity(this.selectedRegion);
      this.cityArray = response;
    },
    initiateList() {
      let self = this;
      $(".list-view .btn").each(function () {
        $(this).siblings(".btn").removeClass("active");
      });
    },
    display(view) {
      $(".products-list").removeClass("list grid").addClass(view);
      $(".list-view .btn").removeClass("active");
      if (view == "list") {
        //$('.products-list .product-layout').addClass('col-lg-12');
        // $('.products-list .product-layout .left-block').addClass('col-md-4');
        // $('.products-list .product-layout .right-block').addClass('col-md-8');
        $(".products-list .product-layout .item-desc").removeClass("hidden");
        $(".products-list .product-layout .list-block").removeClass("hidden");
        $(".products-list .product-layout .button-group").addClass("hidden");
        $(".list-view ." + view).addClass("active");
        $(".list-view .btn").removeClass("active");
        $.cookie("display", "list");
      } else {
        //$('.products-list .product-layout').removeClass('col-lg-12');
        //$('.products-list .product-layout .left-block').removeClass('col-md-4');
        //$('.products-list .product-layout .right-block').removeClass('col-md-8');
        $(".products-list .product-layout .item-desc").addClass("hidden");
        $(".products-list .product-layout .list-block").addClass("hidden");
        $(".products-list .product-layout .button-group").removeClass("hidden");
        $(".list-view ." + view).addClass("active");
        $(".list-view .btn").removeClass("active");
        $.cookie("display", "grid");
      }

      this.showmode = view;
    },
    getProductsHomePage: async function (params) {
      this.blockLoading = true;

      try {
        this.noproducts = false;
        this.gridblocks = [];

        params = "";
        if (localStorage.agent_guestdata) {
          let agentguest_obj = JSON.parse(localStorage.agent_guestdata);
          if (agentguest_obj.selectedcity !== "") {
            params += "?city=" + agentguest_obj.selectedcity;
          }
        }
        let response = await this.getProductListByHPCategories(params);

        for (let res_index = 0; res_index < response.length; res_index++) {
          let productlist = response[res_index].products;

          let blockdata = [];
          for (let index = 0; index < productlist.length; index++) {

            if (index === 10) {
              break;
            }

            let imgs = [];
            let imagearray = productlist[index].show_image;
            for (let index2 = 0; index2 < imagearray.length; index2++) {
              let imgpath = imagearray[index2].image;
              let is1 = imgpath.split("upload/")[0];
              let is4 = imgpath.split("upload/")[1];
              let is2 = "upload/q_auto,f_auto/w_300,h_300,c_fill/";
              // let is3 = is4.split("/")[1];
              imgpath = is1 + is2 + is4;
              imgs.push(imgpath);
            }

            if (productlist[index].rate.toString().includes(".")) {
              let value1 = productlist[index].rate.toString().split(".");
              productlist[index].rateAvg = value1[0];
              if (value1[1] === "5" || value1[1] > "5") {
                productlist[index].rateAvg =
                  parseInt(productlist[index].rateAvg) + 1;
              }
            } else {
              productlist[index].rateAvg = productlist[index].rate;
            }

            let oldprice = "";
            let newprice = "";
            if (
              productlist[index].primary_product.special_price != 0 &&
              productlist[index].primary_product.special_price != ""
            ) {
              newprice = productlist[index].primary_product.special_price;
              oldprice = productlist[index].primary_product.price;
            } else {
              newprice = productlist[index].primary_product.price;
              oldprice = "";
            }

            let storeObj = {};
            storeObj._id = productlist[index].primary_product.store
            storeObj.store_name = productlist[index].primary_product.store_name

            let discounpre = "";

            if (oldprice != "") {
              discounpre = ((oldprice - newprice) / oldprice) * 100;
              discounpre = Math.round(discounpre);
            }

            blockdata.push({
              blockdatacomponent: "ProductTile",
              discountprecentage: discounpre,
              imgpath: imgs,
              item_id: productlist[index]._id,
              prediscount: oldprice,
              price: newprice,
              product_name: productlist[index].product_name,
              primary_product: productlist[index].primary_product,
              rating: productlist[index].rateAvg,
              title: productlist[index].product_name,
              wishlit: productlist[index].wishlit,
              store: storeObj,
              url: "/product/" + productlist[index]._id,
              madein_srilanka: productlist[index].madein_srilanka
            });
          }

          let finalobj = {
            title: response[res_index].name,
            _id: response[res_index]._id,
            pagi: 5,
            data: [],
          };

          if (blockdata.length > 0) {
            if (this.user_role == 2) {
              finalobj.storeName = blockdata[0].store.store_name;
            } else {
              finalobj.storeName = "";
            }
          }

          blockdata.forEach((element) => {
            finalobj.data.push(element);
          });

          if (finalobj.data.length != 0) {
            this.gridblocks.push(finalobj);
          }

          this.display("grid");
            document.getElementById("infiniteScrollLoader").style.display =
              "none";
          this.blockLoading = false;
        }

        if (this.gridblocks.length == 0) {
          this.noproducts = true;
        } else {
          this.noproducts = false;
        }

        for (let index = 0; index < this.gridblocks.length; index++) {
          const element = this.gridblocks[index];
          // console.log(element);
          if (element.title == "New Arrivals") {
            this.homeGridNewArrivals = element.data;
            this.display("grid");
          }

          if (element.title == "Most Popular") {
            this.homeGridMostPopular = element.data;
          }

          if (element.title == "Our Promotions") {
            this.homeGridOurPromotions = element.data;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    getProductsByID: async function (currentpage) {
      this.blockLoading = true;
      try {
        // this.blocks = [];
        this.noproducts = false;
        this.gridblocks = [];
        let CatID = [
          { title: "Groceries | Essentials", cid: "5e824295e7432d5d9d4745b6" },
          { title: "Health & Beauty", cid: "5e91b70fb7edde3ffa421438" },
        ];

        for (let cat_index = 0; cat_index < CatID.length; cat_index++) {
          let params = "category=" + CatID[cat_index].cid;
          params += "&page=" + currentpage;
          params += "&perpage=" + 30;

          if (localStorage.user_city) {
            if (localStorage.user_city !== "") {
              params += "&city=" + localStorage.user_city;
            }
          }

          this.blockLoading = true;
          let productlist = await this.getProductListByCIDFilters(params);

          let blockdata = [];
          for (let index = 0; index < productlist.length; index++) {
            //  console.log(productlist[index]._id);

            let imgs = [];
            let imagearray = productlist[index].show_image;
            for (let index2 = 0; index2 < imagearray.length; index2++) {
              let imgpath = imagearray[index2].image;
              imgs.push(imgpath);
            }

            if (productlist[index].rate.toString().includes(".")) {
              let value1 = productlist[index].rate.toString().split(".");
              productlist[index].rateAvg = value1[0];
              if (value1[1] === "5" || value1[1] > "5") {
                productlist[index].rateAvg =
                  parseInt(productlist[index].rateAvg) + 1;
              }
            } else {
              productlist[index].rateAvg = productlist[index].rate;
            }

            let oldprice = "";
            let newprice = "";
            if (
              productlist[index].primary_product[0].special_price != 0 &&
              productlist[index].primary_product[0].special_price != ""
            ) {
              newprice = productlist[index].primary_product[0].special_price;
              oldprice = productlist[index].primary_product[0].price;
            } else {
              newprice = productlist[index].primary_product[0].price;
              oldprice = "";
            }

            blockdata.push({
              blockdatacomponent: "ProductTile",
              discountprecentage: "",
              imgpath: imgs,
              item_id: productlist[index]._id,
              prediscount: oldprice,
              price: newprice,
              product_name: productlist[index].product_name,
              primary_product: productlist[index].primary_product,
              rating: productlist[index].rateAvg,
              title: productlist[index].product_name,
              wishlit: productlist[index].wishlit,
              url: "/product/" + productlist[index]._id,
              madein_srilanka: productlist[index].madein_srilanka
            });
          }

          let finalobj = {
            title: CatID[cat_index].title,
            data: [],
          };

          blockdata.forEach((element) => {
            finalobj.data.push(element);
          });

          this.gridblocks.push(finalobj);

          this.display("grid");
          this.blockLoading = false;
        }

        // if(this.$store.state.filterdata != null){
        //     if(this.$store.state.filterdata.brands.length > 0){
        //         for (let index = 0; index < this.$store.state.filterdata.brands.length; index++) {
        //             params += "&brands="+ this.$store.state.filterdata.brands[index];
        //         }
        //     }
        //     if(this.$store.state.filterdata.attributes.length > 0){
        //         for (let index = 0; index < this.$store.state.filterdata.attributes.length; index++) {
        //             params += "&attribute_values="+ this.$store.state.filterdata.attributes[index];
        //         }
        //     }

        //     if(this.$store.state.filterdata.searchtext !=  ''){
        //             params += "&name="+ this.$store.state.filterdata.searchtext;
        //     }

        //     console.log(this.$store.state.filterdata);
        // }

        this.blockLoading = false;
      } catch (error) {
        this.blockLoading = false;
      }
    },
    menuLock() {
      if (!document.body.classList.contains("common-home")) {
        var body = document.body;
        body.classList.add("common-home");
      }
    },
    addToWishList: async function (item_id) {
      try {
        let wishlistItem = { product_id: item_id, status: 1 };
        let response = await this.saveWishliat(wishlistItem);
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Product Added to Wishlist",
          showConfirmButton: false,
          timer: 1500,
        });
        this.selectedProduct.wishlit = true;
        this.setProductInfo();
      } catch (error) {
        throw error;
      }
    },
    removeFromWishList: async function (item_id) {
      try {
        let wishlistItem = { product_id: item_id, status: 2 };
        let response = await this.saveWishliat(wishlistItem);
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Product Removed from Wishlist",
          showConfirmButton: false,
          timer: 1500,
        });
        this.selectedProduct.wishlit = false;
        this.setProductInfo();
      } catch (error) {
        throw error;
      }
    },
    getTrendingCategories: async function () {
      try {
        let response = await this.getTrendingCategoriesApi();
        if (response) {
          let index = -1;
          for (let i = 0; i < this.blocks.length; i++) {
            if (
              this.blocks[i].title != undefined &&
              this.blocks[i].title == "TRENDING CATEGORIES"
            ) {
              this.blocks[i].blockdata = [];
              index = i;
            }
          }
          response.forEach((element) => {
            let object = {
              blockdatacomponent: "ProductTileGrid",
              item_id: "",
              url: "",
              imgpath: [],
              title: "",
            };
            object.item_id = element._id;
            object.url = "/productlist?id=" + element._id;
            object.imgpath = [];
            for (let i = 0; i < 3; i++) {
              let imageStr = "";
              if (element.product_images[i] != undefined) {
                imageStr = element.product_images[i].image;
              } else {
                imageStr =
                  "https://res.cloudinary.com/persystance-pvt/image/upload/v1580911015/puwuft3m0jiiyvrlnkwv.jpg";
              }
              object.imgpath.push(imageStr);
            }
            object.title = element.category_name;
            this.trendingCategories.push(object);
          });
          this.blocks[index].blockdata = this.trendingCategories;
        }
      } catch (error) {
        throw error;
      }
    },
    // getPageHome(currentpage) {
    getHomePageProductList: async function (currentpage) {
      let data = this.$store.state.globalcomponentdata.filter(
        (g) => g.page == currentpage
      );
      this.blocks = data[0].blocks;

      let CatID = [
        //{title: "Sathosa Products", cid: "5e824295e7432d5d9d4745b6"}
        // {title: "Electronic Accessories", cid: "5d8e0a2c75f07306d41908aa"},
        // {title: "Baby Toys", cid: "5d91a66885bef955bebe83cf"}
      ];

      for (let index = 0; index < CatID.length; index++) {
        let responseByCatID = await this.getProductListByCategoryID(
          CatID[index].cid
        );
        let blockdata2 = [];
        for (let index2 = 0; index2 < responseByCatID.length; index2++) {
          let imgs = [];
          let imagearray = responseByCatID[index2].show_image;
          for (let index3 = 0; index3 < imagearray.length; index3++) {
            let imgpath = imagearray[index3].image;
            // let is1 = imgpath.split("upload/")[0];
            // let is4 = imgpath.split("upload/")[1];
            // let is2 = "upload/q_auto,f_auto/w_300,h_300,c_fill/";
            // let is3 = is4.split("/")[1];
            // imgpath = is1 + is2 + is4;
            imgs.push(imgpath);
          }

          if (responseByCatID[index2].rate.toString().includes(".")) {
            let value1 = responseByCatID[index2].rate.toString().split(".");
            responseByCatID[index2].rateAvg = value1[0];
            if (value1[1] === "5" || value1[1] > "5") {
              responseByCatID[index2].rateAvg =
                parseInt(responseByCatID[index2].rateAvg) + 1;
            }
          } else {
            responseByCatID[index2].rateAvg = responseByCatID[index2].rate;
          }

          let oldprice = "";
          let newprice = "";
          if (
            responseByCatID[index2].simple_products[0].special_price != 0 &&
            responseByCatID[index2].simple_products[0].special_price != ""
          ) {
            newprice = responseByCatID[index2].simple_products[0].special_price;
            oldprice = responseByCatID[index2].simple_products[0].price;
          } else {
            newprice = responseByCatID[index2].simple_products[0].price;
            oldprice = "";
          }
          this.product_id = responseByCatID[index2]._id;
          blockdata2.push({
            blockdatacomponent: "ProductTile",
            discountprecentage: "",
            imgpath: imgs,
            item_id: responseByCatID[index2]._id,
            prediscount: oldprice,
            price: newprice,
            product_name: responseByCatID[index2].product_name,
            primary_product: responseByCatID[index2].primary_product,
            rating: responseByCatID[index2].rateAvg,
            title: responseByCatID[index2].product_name,
            wishlit: responseByCatID[index2].wishlit,
            url: "/product/" + responseByCatID[index2]._id,
          });
        }
        this.blocks.unshift({
          title: CatID[index].title,
          blockcompnent: "ProductTileGrid",
          blockdata: blockdata2,
          showBlock: true,
        });
      }

      //let productlist = await this.getProductList();

      let flashdata = await this.getFlashDeals();
      let qty = flashdata.now.qty;
      let productlist = [];
      if (flashdata.now.products) {
        productlist = flashdata.now.products;
        // this.dealTime(data.time_now, data.now.end_date_time);

        let blockdata = [];
        for (let index = 0; index < productlist.length; index++) {
          // productlist[index].simple_product.sold_amount = 50;
          let SoldPresentage =
            (productlist[index].simple_product.sold_amount / qty) * 100 + "%";
          //CHANGE THIS WITH SHYAMIN
          let imgs = [];
          let imagearray = productlist[index].simple_product.images;
          if (productlist[index].simple_product !== null) {
            for (let index2 = 0; index2 < imagearray.length; index2++) {
              let imgpath = imagearray[index2].image;
              imgs.push(imgpath);
            }
          }

          if (productlist[index].rating.toString().includes(".")) {
            let value = productlist[index].rating.toString().split(".");
            productlist[index].rateAvg = value[0];
            if (value[1] === "5" || value[1] > "5") {
              productlist[index].rateAvg =
                parseInt(productlist[index].rateAvg) + 1;
            }
          } else {
            productlist[index].rateAvg = productlist[index].rating;
          }

          let oldprice = "";
          let newprice = "";
          let discount = "";

          if (
            productlist[index].simple_product.special_price != 0 &&
            productlist[index].simple_product.special_price != ""
          ) {
            newprice = productlist[index].simple_product.special_price;
            oldprice = productlist[index].simple_product.price;

            let balance = oldprice - newprice;
            discount = (balance / oldprice) * 100;
            discount = discount.toFixed(0);
          } else {
            newprice = productlist[index].simple_product.price;
            oldprice = "";
          }

          blockdata.push({
            blockdatacomponent: "ProductTileDeal",
            discountprecentage: "",
            imgpath: imgs,
            item_id: productlist[index].simple_product.product_id,
            prediscount: oldprice,
            discount: discount,
            price: newprice,
            sold_amount: productlist[index].simple_product.sold_amount,
            SoldPresentage: SoldPresentage,
            product_name: productlist[index].product.product_name,
            primary_product: productlist[index].simple_product,
            rating: productlist[index].rateAvg,
            title: productlist[index].product.product_name,
            wishlit: productlist[index].wishlit,
            url: "/product/" + productlist[index].simple_product.product_id,
          });
        }

        this.dealTime(flashdata.time_now, flashdata.now.end_date_time);
        this.blocks.unshift({
          title: "FLASH DEALS",
          blockcompnent: "ProductSliderBlock",
          blockdata: blockdata,
          deal_start: flashdata.time_now,
          deal_end: flashdata.now.end_date_time,
          dealHours: 0,
          dealMinutes: "0.0",
          dealSeconds: 0,
          showBlock: true,
        });
      }

      this.finalblocks = this.blocks;
      this.blockLoading = false;
    },
    dealTime(startdate, dateend) {
      var startDate = new Date(startdate);
      // Do your operations
      var endDate = new Date(dateend);
      var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
      clearInterval(homdealtimer);
      if (seconds <= 0) {
        this.dealHours = 0;
        this.dealMinutes = "0.0";
        this.dealSeconds = 0;
        return;
      }
      let self = this;
      var homdealtimer = setInterval(function () {
        seconds = seconds - 1;
        if (self.$route.name != "Buyer Home") {
          clearInterval(homdealtimer);
        }
        if (seconds <= 1) {
          self.blocks.forEach(function (value, i) {
            if (value.title === "FLASH DEALS") {
              self.$set(self.blocks[i], "showBlock", false);
            }
          });
        }
        if (seconds <= 0) {
          clearInterval(homdealtimer);
          return;
        }

        var minutes = Math.floor(seconds / 60);
        var hours = Math.floor(seconds / 3600);
        var textseconds = seconds - minutes * 60;
        var textminutes = String((seconds % 3600) / 60);
        self.blocks.forEach(function (value, i) {
          if (value.title === "FLASH DEALS") {
            self.$set(self.blocks[i], "dealHours", hours);
            self.$set(self.blocks[i], "dealMinutes", textminutes);
            self.$set(self.blocks[i], "dealSeconds", textseconds);
          }
        });

        self.dealHours = hours;
        self.dealMinutes = textminutes;
        self.dealSeconds = textseconds;
      }, 1000);
    },
    pushToCart() {
      $("#productModalCenter").modal("hide");
      this.$router.push("/buyer-cart");
    },
    parentModalTrigger(pid) {
      this.uniqcount = this.uniqcount + 1;
      this.product_id = pid;

      this.inputNumValue = 1;
      this.isDisable = true;
      this.idObj = {
        ids: [],
      };

      //    this.setProductInfo();
      $("#productModalCenter").modal("show");
    },
    parentModalReset() {
      this.product_id = "";

      this.inputNumValue = 1;
      this.isDisable = true;
      this.idObj = {
        ids: [],
      };
    },
    haddleClickViewAll: function(id) {
      this.$router.push("/productlistall?id=" + id);
    }
  }
};
</script>

<style scoped>
.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}

#content {
  margin-bottom: 0% !important;
}

.sk-chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: #5779ae;
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

#productModalLongTitle {
  font-size: 18px;
  color: black;
  width: fit-content;
}

.sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}
.input-tel__label {
  display: none !important;
}
@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}

#preview-area {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
  /* bottom: 60px; */
  right: 0;
  margin: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
