<template>
  <div>
    <div class="item">
      <div
        style="padding-left: 0px;"
        v-bind:class="
          noslide
            ? ' product-layout col-lg-12 col-md-4 col-sm-6 col-xs-12'
            : 'item-inner product-layout transition product-grid'
        "
      >
        <div
          class="product-item-container product-grid4"
          style="padding-top: 10px;"
        >
          <div class="left-block left-b">
            <div class="box-label">
              <span
                class="label-product label-sale"
                v-if="data.discountprecentage != ''"
                >-{{ data.discountprecentage }}%</span
              >
            </div>
            <div class="product-image-container second_img">
              <a
                @click="viewProduct(data.item_id)"
                target="_self"
                :title="data.title"
                v-if="data.imgpath.length > 1"
              >
                <img
                  v-for="(imagepath, index) in data.imgpath.slice(0, 2)"
                  :key="index"
                  :src="imagepath"
                  :class="'img-responsive img-' + parseInt(index + 1)"
                  style="height: 180px; width: 180px; object-fit: cover;"
                />
              </a>
              <a
                @click="viewProduct(data.item_id)"
                target="_self"
                :title="data.title"
                v-if="data.imgpath.length === 1"
              >
                <img
                  v-for="index in 2"
                  :key="index"
                  :src="data.imgpath[0]"
                  :class="'img-responsive img-' + parseInt(index + 1)"
                  style="height: 180px; width: 180px; object-fit: cover;"
                />
              </a>
            </div>
            <!--quickview-->
            <!-- <div class="so-quickview">                   
                    <a @click="viewProduct(data.item_id)" class="btn-button quickview quickview_handler visible-lg"><i class="fa fa-eye"></i><span>Quick view</span></a>
            </div>-->
            <!--end quickview-->
          </div>
          <div class="right-block">
            <div
              class="button-group so-quickview cartinfo--left"
              style="display:none;"
            >
              <!-- <button @click="addToCart(data)" type="button" class="addToCart" title="Add to cart">
                        <span>Add to cart </span>   
              </button>-->
              <button
                @click="quickViewProductModal(data.item_id)"
                type="button"
                class="addToCart"
                title="Add to cart"
              >
                <span>Add to cart</span>
              </button>

              <button
                v-if="data.wishlit === false"
                type="button"
                class="wishlist btn-button"
                title="Add to Wish List"
                @click="addToWishList(data, data.item_id)"
              >
                <i class="fa fa-heart-o"></i>
                <span>Add to Wish List</span>
              </button>

              <button
                v-if="data.wishlit === true"
                type="button"
                class="wishlist btn-button"
                style="color: #fff; background-color: #005baa;"
                title="Remove to Wish List"
                @click="removeFromWishList(data, data.item_id)"
              >
                <i class="fa fa-heart-o"></i>
              </button>

              <!-- <button type="button" class="compare btn-button" title="Compare this Product "><i class="fa fa-retweet"></i><span>Compare this Product</span>
              </button>-->
            </div>
            <!-- REMOVE ANIMATION <div class="caption hide-cont"> -->
            <div class="caption">
              <div class="ratings" style="display:none;">
                <div class="rating-box">
                  <span class="fa fa-stack">
                    <i
                      :class="[
                        data.rating >= 1 ? ' fa fa-star' : 'fa fa-star-o',
                      ]"
                      class="fa-stack-2x"
                    ></i>
                  </span>
                  <span class="fa fa-stack">
                    <i
                      :class="[
                        data.rating >= 2 ? ' fa fa-star' : 'fa fa-star-o',
                      ]"
                      class="fa-stack-2x"
                    ></i>
                  </span>
                  <span class="fa fa-stack">
                    <i
                      :class="[
                        data.rating >= 3 ? ' fa fa-star' : 'fa fa-star-o',
                      ]"
                      class="fa-stack-2x"
                    ></i>
                  </span>
                  <span class="fa fa-stack">
                    <i
                      :class="[
                        data.rating >= 4 ? ' fa fa-star' : 'fa fa-star-o',
                      ]"
                      class="fa-stack-2x"
                    ></i>
                  </span>
                  <span class="fa fa-stack">
                    <i
                      :class="[
                        data.rating >= 5 ? ' fa fa-star' : 'fa fa-star-o',
                      ]"
                      class="fa-stack-2x"
                    ></i>
                  </span>
                </div>
                <!-- <span v-if="data.rating != '0'" class="rating-num">( {{data.rating}} )</span> -->
              </div>

              <div class="col-md-6" style="padding-right: 0px;">
                <button
                  @click="addToWishList(data, data.item_id)"
                  type="button"
                  class="addToCartNew"
                  title="Wish List"
                >
                  <i class="fa fa-heart" style="font-size: large;"></i
                  ><span> WISH LIST</span>
                </button>
              </div>
              <div class="col-md-6" style="padding-left: 0px;">
                <button
                  @click="quickViewProductModal(data.item_id)"
                  type="button"
                  class="addToCartNew"
                  title="Add to cart"
                >
                  <i class="fa fa-shopping-cart" style="font-size: large;"></i
                  ><span> ADD TO CART</span>
                </button>
              </div>

              <div style="height:35px; overflow:hidden;">
                <!-- <h4 style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"><a @click="viewProduct(data.item_id)" :title="data.title" target="_self">{{data.title}}</a></h4> -->
                <h4
                  style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;"
                >
                  <a
                    @click="viewProduct(data.item_id)"
                    :title="data.title"
                    target="_self"
                    >{{ data.title }}</a
                  >
                </h4>
              </div>
            </div>

            <h4
              style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;"
            >
              <a
                @click="viewProduct(data.item_id)"
                :title="data.title"
                target="_self"
                >{{ data.title }}</a
              >
            </h4>

            <p class="price">
              <span style="color: #E82D2B;" class="price-new"
                >Rs.{{ data.price }}</span
              >&nbsp;&nbsp;
              <span
                class="price-old"
                v-if="data.prediscount != '' && data.prediscount !== data.price"
                >Rs.{{ data.prediscount }}</span
              >
            </p>
            <buyer-login-modal ref="modalLogin" />
          </div>
        </div>
      </div>
      <!-- <modal ref="modal"></modal>    -->
    </div>
  </div>
</template>
<script>
import modal from "@/components/product/quick_view_product_modal.vue";
import APIProduct from "@/mixins/buyer/APIProduct";
import BuyerLoginModal from "@/views/Buyer/BuyerLogin/BuyerLloginModal.vue";
export default {
  name: "ProductTile",
  mixins: [APIProduct],
  props: {
    data: {
      type: Object,
    },
    noslide: {
      type: Boolean,
    },
  },
  data: function() {
    return {
      cartProductIdArray: [],
      idObj: {
        ids: [],
      },
    };
  },
  components: { modal, BuyerLoginModal },
  methods: {
    quickViewProductModal: function(pid) {
      if (localStorage.getItem("buyer_token")) {
        this.$parent.parentModalTrigger(pid);
      } else {
        this.$refs.modalLogin.showModal();
      }
    },
    viewProduct: function(item_id) {
      this.$router.push({ path: "product", query: { pid: item_id } });
    },
    addToCart: function(item) {
      this.idObj = JSON.parse(localStorage.getItem("cart_product_ids"));
      if (this.idObj !== null) {
        this.idObj.ids.push(item);
        localStorage.setItem("cart_product_ids", JSON.stringify(this.idObj));
        this.$store.commit("setAddToCartSwitchState", {
          action: null,
          payload: this.idObj,
        });
      } else {
        let array = {
          ids: [],
        };
        array.ids.push(item);
        localStorage.setItem("cart_product_ids", JSON.stringify(array));
        this.$store.commit("setAddToCartSwitchState", {
          action: null,
          payload: array,
        });
      }
    },
    addToWishList: async function(data, item_id) {
      if (this.$store.state.buyer_accessToken === null) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Please login to add to Wishlist!",
          showConfirmButton: false,
          timer: 1500,
        });

        return;
      } else {
        try {
          let wishlistItem = { product_id: item_id, status: 1 };
          let response = await this.saveWishliat(wishlistItem);
          this.$swal.fire({
            position: "center",
            type: "success",
            title: "Product Added to Wishlist",
            showConfirmButton: false,
            timer: 1500,
          });
          data.wishlit = true;
        } catch (error) {
          throw error;
        }
      }
    },
    removeFromWishList: async function(data, item_id) {
      try {
        let wishlistItem = { product_id: item_id, status: 2 };
        let response = await this.saveWishliat(wishlistItem);
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Product Removed from Wishlist",
          showConfirmButton: false,
          timer: 1500,
        });
        data.wishlit = false;
      } catch (error) {
        throw error;
      }
    },
  },
};
</script>
<style scoped>
.addToCartNew {
  background: #ffd338 !important;
  font-size: 12px;
  font-weight: 600;
  color: #333333;
  text-transform: capitalize;
  border-radius: 6px;
  border: none;
  padding: 0 10px;
  height: 34px;
  line-height: 34px;
  line-height: 100%;
  border: none;
}
.addToCart {
  background: #e82d2b !important;
}
.wishlist {
  border: 1px solid #e82d2b !important;
}
.wishlist .fa {
  color: #e82d2b;
}
.wishlist:hover {
  background: #505050 !important;
}
.wishlist:hover > .fa {
  color: #ffffff;
}

/* Sathosa UI  */
.product-grid4 {
  border-radius: 10px !important;
  border: 1px solid #ebebeb;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.product-grid4:hover {
  box-shadow: 0 0 10px rgba(187, 57, 40, 0.6);
}
</style>
